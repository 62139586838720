.circle {
  position: absolute;
  top: 47%;
  left: 42.5%;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  z-index: 10;
  &::before {
    display: block;
    position: relative;
    content: '';
    width: 5px;
    height: 5px;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid #fff;
    animation: pulsePoint 1s infinite;
  }
}

.base-client {
  position: absolute;
  top: 47%;
  left: 45.5%;
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 50%;
  z-index: 10;
  &::before {
    display: block;
    position: relative;
    content: '';
    width: 5px;
    height: 5px;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    background-color: black;
    border: 1px solid black;
    opacity: 0.5;
    animation: blinker 1.2s linear infinite;
    transform: translate(-1.5px, -1.5px) scale(2.5);
  }

  &.jumspot-style {
    background-color: white;

    &::before {
      border: 1px solid white;
      background-color: white;
    }
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes pulsePoint {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
