.square-jumpspot {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 200px;
  width: auto;
  height: 34px;
  cursor: pointer;

  &--container {
    width: inherit;
    height: inherit;
    background-color: #d9d9da;
    display: flex;
    align-items: center;
    border-radius: 17px;
    padding: 3px;
  }

  &--previewimg {
    width: 28px;
    height: 28px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &--title {
    margin-left: 4px;
    margin-right: 6px;
    p {
      font-size: 12px;
      color: #21201c;
      font-family: var(--montserrat-font);
      min-width: 152px;
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.square-icons {
  display: flex;
  align-items: center;
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &--img {
    object-fit: cover;
    width: 36px;
    height: 36px;
    border-radius: 5px;
  }

  &--container {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: #d9d9da;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    white-space: nowrap;
  }

  &--icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--extra-show {
    display: none;
    width: 0;
    transition: 0.7s ease;
    flex-direction: column;
    align-items: flex-start;
    font-family: var(--montserrat-font);
  }

  &--container:hover {
    width: 210px;
  }

  &--container:hover .square-icons--extra-show {
    display: flex;
    width: auto;
    pointer-events: all;
  }

  &--title {
    font-size: 12px;
    color: black;
    p {
      font-family: var(--montserrat-font) !important;
    }
  }

  &--type {
    color: #949596;
    font-size: 12px;
    p {
      font-family: var(--montserrat-font) !important;
    }
  }
}

.square-infotag {
  display: flex;
  align-items: center;
  position: relative;
  width: 40px;
  height: auto;

  &--container {
    padding: 8px;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 250px;
    max-width: 300px;
    height: auto;
    background-color: #ffffffcc;
  }

  &--title,
  &--desc,
  &--readmore {
    p,
    a {
      font-family: var(--montserrat-font);
      text-decoration: none;
    }
  }

  &--title {
    color: black;
    font-size: 12px;
    font-weight: bold;
    p {
      max-width: 276px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--desc {
    color: #808080;
    font-size: 10px;
    margin-top: 2px;
  }

  &--readmore {
    margin-top: 2px;
    font-size: 10px;
    color: #ca000ace;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0.85;
    &:hover {
      opacity: 1;
    }

    cursor: pointer;
    a {
      color: #ca000ace;
    }
  }
}
