.banner {
  background-color: #1e164e;
  color: #eaca14;
  padding: 10px;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  gap: 5px;
  pointer-events: all;

  &-button {
    text-decoration: underline;
    cursor: pointer;
    user-select: unset;
  }
}
